.loadingContainer {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    background-color: #e3fef2;
}

.loadingText {
    color: #371db4;
    font-size: 24px;
    margin-bottom: 20px;
    font-family: 'NanumSquare', sans-serif;
}

.tapeImage {
    width: 200px;
    height: auto;
    margin-bottom: 30px;
}

.loadingSpinner {
    width: 50px;
    height: 50px;
    border: 4px solid #e3fef2;
    border-top: 4px solid #371db4;
    border-radius: 50%;
    animation: spin 1s linear infinite;
}

@keyframes spin {
    0% { transform: rotate(0deg); }
    100% { transform: rotate(360deg); }
}


a {
    text-decoration: none;
    color: inherit;
}.container {
     position: relative;
     background-color: #e3fef2;
     float: left;
     height: 100%;
     width: 100%;
     margin: 0;
     left: 0;
     top: 0;
     overflow: auto;
     display: flex;
     flex-direction: row;
     font-family: 'NanumSquare', sans-serif;
     flex: 1;
 }
 .textBox{
     margin-left:10px;
     margin-top:10px;
 }
.textBox p {
    font-size: 14px;
    line-height: 1.2;
    margin: 0;
    padding: 0;
}

.textBox p:first-child {
    margin-bottom: 8px;
    margin-top: 6px;
}

.header {
    display: flex;
    flex: 0 1 430px;
    flex-direction: column;
    text-align: left;
    color: #371db4;
    width: 30%;
}

.content {
    display: flex;
    flex: 1;
    flex-direction: column;
    text-align: left;
    color: #371db4;
}

.boxContainer {
    display: flex;
    flex-direction: column;
    flex: 1;
    margin-top: 120px;
}

.box {
    margin-bottom: 27px;
    width: 80%;
    height: 46px;
    border: solid 1px #371db4;
    background-color: #ffffff;
    align-items: center;
}

.box:hover {
    background-color: #371db4;
}

.box:hover .mailTitle {
    color: #ffffff;
}

.mailTitle {
    margin-left: 19px;
    vertical-align: middle;
    font-size: 18px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.5;
    letter-spacing: normal;
    text-align: left;
    color: #371db4;
    padding-top: 10px;
}

.goBack {
    width: 36px;
    height: 42px;
    margin-top: 131px;
    margin-left: 140px;
    display: block;
}

.goBack img {
    width: 100%;
    height: 100%;
    object-fit: contain;
}

.titleSection {
    padding-top: 97px;
    margin-left: 140px;
}

.title {
    font-size: 30px;
}

.subtitle {
    padding: 0;
    font-size: 18px;
}

.opMessage {
    margin-top: 15px;
    margin-right: 20px;
    font-size: 16px;
}

.navigation {
    display: flex;
    flex-direction: row;
    margin-top: 47px;
    margin-bottom: 40px;
    justify-content: center;
    align-items: center;
    gap: 8px;
}

.navButton {
    font-size: 24px;
    color: #371db4;
    cursor: pointer;
    border: none;
    background: transparent;
    padding: 0;
    margin: 0 10px;
    display: flex;
    align-items: center;
    justify-content: center;
    outline: none;
    appearance: none;
    -webkit-appearance: none;
    -moz-appearance: none;
}

.navButton:disabled {
    opacity: 0.5;
    cursor: not-allowed;
}

.navButton:focus {
    outline: none;
}

@media (max-width: 1230px) {
    .navButton {
        font-size: 20px;
        margin: 0 5px;
    }
}

.pageNumber {
    display: inline-block;
    margin: 0 10px;
    width: 40px;
    height: 40px;
    position: relative;
}

.pageNumber span {
    display: block;
    width: 20px;
    height: 20px;
    font-size: 18px;
    margin-left: 10px;
    margin-top: 10px;
    cursor: pointer;
    text-align: center;
    color: #371db4;
}

.current {
    background: #e3fef2;
}

.triangle {
    position: absolute;
    margin-top: -15px;
    margin-left: 5px;
    width: 0;
    height: 0;
    border-left: 20px solid transparent;
    border-right: 20px solid transparent;
    border-bottom: 40px solid rgba(55, 29, 180, 0.16);
    transform: rotate(50deg);
}

.pc {
    display: inline;
}

.mobile {
    display: none;
}

@media (max-width: 1230px) {
    .current {
        background: #ffffff !important;
    }

    .container {
        flex-direction: column;
        align-items: center;
        justify-content: center;
        background-color: #ffffff;
    }

    .boxContainer {
        width: 100%;
        margin: 0;
        padding: 0;
    }

    .box {
        width: 100%;
        padding: 0;
        height: 61px;
        box-shadow: 10px 3px 6px 0 rgba(0, 160, 142, 0.293);
        background-color: rgba(29, 180, 128, 0.08);
        display: block;
        text-overflow: ellipsis;
        white-space: nowrap;
        overflow: hidden;
    }

    .content {
        width: 82%;
        align-items: center;
        justify-content: center;
        margin-top: 15px;
    }

    .header {
        width: 82%;
        flex-direction: row;
        flex: 1;
        justify-content: center;
        align-items: center;
        margin-top: 66px;
    }

    .goBack {
        height: 31px;
        width: auto;
        /*flex: 1;*/
        margin-top: 0;
        margin-left: 0;
    }

    .titleSection {
        padding-top: 0;
        margin-top: 0;
        margin-left: 0;
        flex: 1;
    }

    .title {
        display: none;
    }

    .subtitle {
        margin-left: 30px;
        padding: 0;
        font-size: 18px;
    }

    .opMessage {
        margin-top: 5px;
        margin-left: 30px;
        font-size: 13px;
    }

    .navigation {
        margin-top: 3px;
    }

    .triangle {
        margin-top: -10px;
        margin-left: 3px;
        border-left: 12px solid transparent;
        border-right: 12px solid transparent;
        border-bottom: 24px solid rgba(55, 29, 180, 0.16);
    }

    .pageNumber {
        margin: 0 6px;
        width: 30px;
        height: 30px;
    }

    .pageNumber span {
        width: 10px;
        height: 10px;
        font-size: 14px;
        margin-left: 5px;
        margin-top: 5px;
    }

    .pc {
        display: none;
    }

    .mobile {
        display: inline;
        font-size: 14px;
        flex-direction: column;
        line-height: 4px;
        text-align: center;
    }

    .mailTitle {
        margin-left: 0;
        text-align: center;
        font-size:14px;
        padding: 0px;
    }

    .special {
        margin-top: 12px;
    }
    .textBox p {
        font-size: 14px;
        line-height: 1.2;
        margin: 0;
        padding: 0;
    }

    .textBox p:first-child {
        margin-bottom: 8px;
        margin-top: 6px;
    }
}
