.mainpage-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
    height: 100vh;
}

.mainpage-section {
    flex: 1;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
}

.mainpage-footer {
    height: 100px;
    width: 100%;
}
