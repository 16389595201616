body {
    font-family: 'NanumSquare', sans-serif;
}

.makers-normal { font-weight: 400; }
.makers-bold { font-weight: 700; }
.makers-bolder { font-weight: 800; }
.makers-light { font-weight: 300; }

.makers-container {
    height: 924px;
    width: 100%;
    padding: 0;
    margin: 0;
    float: left;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    font-family: 'NanumSquare', sans-serif;
}

.makers-header {
    height: 60px;
    margin: 0;
    margin-top: 80px;
    font-family: NanumSquareR;
    font-size: 15px;
    font-weight: normal;
    font-stretch: normal;
    flex: 1;
    justify-content: center;
    align-items: center;
    font-family: 'NanumSquare', sans-serif;
}

.makers-title {
    font-family: NanumSquareR;
    font-size: 30px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.17;
    letter-spacing: normal;
    text-align: center;
    color: #371db4;
}

.makers-subtitle {
    font-family: NanumSquareR;
    font-size: 18px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.94;
    letter-spacing: normal;
    text-align: center;
    color: #371db4;
}

.makers-name {
    margin-top: 25px;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.94;
    letter-spacing: normal;
    text-align: center;
    color: #371db4;
    padding: 0;
    font-size: 18px;
}

.makers-introduction {
    font-size: 16px;
    margin-top: 23px;
    padding: 0;
    font-stretch: normal;
    line-height: 1.94;
    letter-spacing: normal;
    text-align: center;
    color: #371db4;
    height: 127px;
    width: 433px;
}

.makers-body {
    width: 100%;
    display: flex;
    flex-direction: row;
    flex: 1;
    margin-top: 137px;
}

.makers-person {
    box-sizing: border-box;
    float: left;
    width: 390px;
    margin: 3px;
    padding: 0;
    flex: 1;
    display: flex;
    flex-direction: column;
    align-items: center;
}

.makers-icon {
    width: 106px;
    height: 102px;
    object-fit: contain;
}

@media screen and (max-width: 1200px) {
    .makers-container {
        height: 1300px;
    }
    .makers-body {
        width: 100%;
        display: flex;
        flex-direction: column;
        flex: 1;
        align-items: center;
        justify-content: center;
        margin-top: 80px;
    }

    .makers-header {
        margin-top: 20px;
    }

    .makers-person {
        margin-top: 20px;
        width: 100%;
        flex: 1;
        min-height: 300px;
    }

    .makers-introduction {
        font-size: 14px;
    }
    .makers-introduction {
        width: 100%;
    }
}

/* iPhone 6,7,8 + Plus */
@media only screen and (min-device-width: 375px) and (max-device-width: 667px) {
    .makers-person {
        max-width: 330px;
    }

    .makers-introduction {
        font-size: 14px;
    }

    .makers-title {
        font-size: 26px;
    }

    .makers-subtitle {
        font-size: 16px;
    }
}

/* Galaxy S6 */
@media screen and (device-width: 360px) and (device-height: 640px) and (-webkit-device-pixel-ratio: 4) {
    .makers-person {
        max-width: 330px;
    }

    .makers-introduction {
        font-size: 14px;
    }

    .makers-title {
        font-size: 26px;
    }

    .makers-subtitle {
        font-size: 16px;
    }
}

/* Galaxy S7 and S8 */
@media only screen and (min-device-width: 360px) and (max-device-width: 640px) {
    .makers-person {
        max-width: 330px;
    }

    .makers-introduction {
        font-size: 13px;
    }

    .makers-title {
        font-size: 26px;
    }

    .makers-subtitle {
        font-size: 16px;
    }
}
