/* MainPart.css */
.mainpart-container {
    position: relative;  /* 이 부분이 중요합니다 */
    height: 1150px;
    width: 100%;        /* 명시적으로 width 설정 */
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    font-family: 'NanumSquare', sans-serif;
    overflow: hidden;   /* 추가: 내부 요소가 넘치지 않도록 */
}

.mainpart-content-wrapper {
    height: 100%;
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    z-index: 3;
    margin: 0;
}

.mainpart-polygon1 {
    position: absolute;
    left: calc(100%/6);
    transform: rotate(-35deg);
    top: 240px;
    z-index: 2;
}

.mainpart-polygon2 {
    position: absolute;
    right: calc(100%/6);
    transform: rotate(-35deg);
    top: 371.5px;
    z-index: 2;
}

.mainpart-big-circle1 {
    position: absolute;
    top: 157px;
    left : calc(100%/3);
    width: 55px;
    height: 55px;
    background: #95fccd;
    border-radius: 50%;
    z-index: 2;
}

.mainpart-big-circle2 {
    position: absolute;
    top: 619px;
    left: calc(100%/4);
    width: 55px;
    height: 55px;
    background: #95fccd;
    border-radius: 50%;
    z-index: 2;
}

.mainpart-small-circle1,
.mainpart-small-circle2,
.mainpart-small-circle3 {
    position: absolute;
    width: 22px;
    height: 22px;
    border-radius: 50%;
    z-index: 2;
}

.mainpart-small-circle1 {
    top: 574px;
    right: calc(100%/4);  /* 1/2에서 1/4로 수정 */
    background: #95fccd;
}

.mainpart-small-circle2 {
    top: 472px;
    left: calc(100%/3.3);
    background-color: #9e8de3;
}

.mainpart-small-circle3 {
    top: 201px;
    right: calc(100%/3.5);
    background-color: #9e8de3;
}

.mainpart-header {
    flex: 1;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

.mainpart-description {
    flex: 1;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

.mainpart-description-text {
    line-height: 1.94;
    letter-spacing: 0.45px;
    text-align: center;
    color: #371db4;
    font-size: 18px;
}

.mainpart-description-text.bold {
    font-weight: 700;
    font-size: 25px;
    margin-top: 38px;
}

.mainpart-subscription {
    width: 453px;
    flex: 1;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    z-index: 1;
}

.mainpart-tape {
    width: 235px;
    height: 139px;
    object-fit: contain;
    margin-top: 157px;
}

.mainpart-title {
    width: 340px;
    height: 96.484px;
    object-fit: contain;
    flex:1;
    margin-top : 3px;
    padding : 0;
}

.mainpart-form {
    width: 100%;
    padding: 30px;
}

.mainpart-form-group {
    margin-bottom: 10px;
}

.mainpart-form-label {
    display: block;
    color: #371db4;
    font-size: 16px;
    text-align: center;
    margin-bottom: 10px;
}

.mainpart-form-input {
    width: 100%;
    padding: 9px 10px;
    border: none;
    border-radius: 10px;
    background-color: #d9ffee;
}

.mainpart-form-error {
    color: #f95a1f;
    padding: 5px 0;
    font-size: 13px;
}

.mainpart-form-checkbox {
    margin: 10px 0;
    display: flex;
    align-items: center;
}

.mainpart-checkbox {
    width: 20px;
    height: 20px;
    border: 2px solid #bcbcbc;
    cursor: pointer;
    margin-right: 8px;
}

.mainpart-submit-button {
    width: 100%;
    height: 40px;
    background: #371db4;
    font-size: 16px;
    color: #fff;
    padding: 0 22px;
    cursor: pointer;
    border: none;
    border-radius: 10px;
    box-shadow: rgba(179, 148, 242, 0.7) 8px 8px 19px 0px;
    margin-top: 10px;
}

.mainpart-submit-button:hover {
    background-color: #4a2ed6;
}

.mainpart-success {
    text-align: center;
    font-size: 16px;
    color: #ff3366;
}

@media (max-width: 1000px) {
    .mainpart-title {
        width: 100%;
        max-width: 500px;
        min-width: 427px;
    }

    .mainpart-tape {
        width: 40%;
        max-width: 235px;
        min-width: 170px;
    }

    .mainpart-polygon1 {
        left: calc(100%/20);
    }

    .mainpart-polygon2 {
        right: calc(100%/19);
    }

    .mainpart-big-circle1 {
        left: calc(100%/4);
    }

    .mainpart-big-circle2 {
        left: calc(100%/8);
    }

    .mainpart-small-circle1 {
        right: calc(100%/6);
    }

    .mainpart-small-circle2 {
        left: calc(100%/4.5);
    }

    .mainpart-small-circle3 {
        right: calc(100%/5.5);
    }
}

@media screen and (max-width: 640px) {
    .mainpart-subscription {
        max-width: 330px;
    }

    .mainpart-form {
        max-width: 330px;
    }

    .mainpart-description-text.bold {
        font-size: 20px;
    }

    .mainpart-description-text {
        font-size: 16px;
    }

    .mainpart-form-label {
        font-size: 16px;
    }

    .mainpart-submit-button {
        font-size: 16px;
    }
}

@media only screen and (max-width: 736px) {
    .mainpart-subscription {
        max-width: 370px;
    }

    .mainpart-form {
        max-width: 360px;
    }

    .mainpart-content-wrapper {
        max-width: 412px;
    }
}

@media only screen and (max-width: 667px) {
    .mainpart-container {
        height: 780px;
    }

    .mainpart-subscription {
        max-width: 370px;
    }

    .mainpart-title {
        max-width: 300px;
        min-width: 300px;
        margin-top: 0;
    }

    .mainpart-tape {
        margin-top: 50px;
    }

    .mainpart-polygon1 {
        top: 100px;
        left: calc(100%/22);
    }

    .mainpart-big-circle1 {
        width: 33px;
        height: 33px;
        top: 40px;
        left: calc(100%/4);
    }

    .mainpart-big-circle2 {
        width: 33px;
        height: 33px;
        top: 380px;
        left: calc(100%/20);
    }

    .mainpart-small-circle2 {
        top: 240px;
        width: 16px;
        height: 16px;
        left: calc(100%/5);
    }

    .mainpart-polygon2 {
        top: 200px;
        right: calc(100%/20);
    }

    .mainpart-small-circle3 {
        width: 16px;
        height: 16px;
        top: 100px;
        right: calc(100%/6);
    }

    .mainpart-small-circle1 {
        width: 16px;
        height: 16px;
        top: 360px;
        right: calc(100%/20);
    }

    .mainpart-content-wrapper {
        max-width: 360px;
    }

    .mainpart-description-text.bold {
        font-size: 20px;
        margin-top: 7px;
    }

    .mainpart-description-text {
        font-size: 14px;
    }

    .mainpart-description-text:not(.bold) {
        margin-top: 7px;
    }

    .mainpart-success {
        font-size: 14px;
    }
}
