.read-letter {
    width: 100%;
    margin: 0;
    padding: 0;
    box-sizing: border-box;
}

.read-letter a {
    text-decoration: none;
    color: inherit;
    display: block;
    height: 100%;
}

.readletter-container {
    width: 100%;
    height: 217px;
    background-color: rgba(149, 252, 205, 0.26);
    padding: 0;
    margin: 0;
    display: flex;
    flex-direction: column;
    box-sizing: border-box;
    transition: background-color 0.3s ease;
}

.read-letter a:hover .readletter-container {
    background-color: #371db4;
    cursor: pointer;
}

.readletter-content {
    margin: auto;
    width: 50%;
    margin-top: 77px;
    font-stretch: normal;
}

.readletter-title {
    font-size: 30px;
    font-style: normal;
    line-height: 1.17;
    letter-spacing: normal;
    text-align: center;
    color: #371db4;
    padding: 0;
    margin: 0;
    border: 0;
    transition: all 0.3s ease;
}

.read-letter a:hover .readletter-title {
    text-shadow: 10px 3px 20px rgba(255, 255, 255, 0.16);
    -webkit-text-stroke: 1px #e3fef2;
    color: #ffffff;
}

.readletter-subtitle {
    padding: 0;
    padding-top: 10px;
    margin: 0;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.94;
    letter-spacing: normal;
    text-align: center;
    color: #371db4;
    transition: all 0.3s ease;
}

.read-letter a:hover .readletter-subtitle {
    /*-webkit-text-stroke: 1px #707070;*/
    color: #ffffff;
}
/* Mobile Responsive Styles */
@media only screen
and (min-device-width: 375px)
and (max-device-width: 667px) {
    .readletter-title {
        font-size: 26px;
    }
    .readletter-subtitle {
        font-size: 16px;
    }
}

/* Galaxy S6, S7, S8 */
@media only screen
and (min-device-width: 360px)
and (max-device-width: 640px) {
    .readletter-title {
        font-size: 26px;
    }
    .readletter-subtitle {
        font-size: 16px;
    }
}
