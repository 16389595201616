/* Footer.css */
.footer-container {
    background-color: #371db4;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    min-height: 125px;
    font-family: 'NanumSquare', sans-serif;
}

.footer-content {
    padding: 10px;
    font-family: 'NanumSquare', sans-serif;
    font-size: 15px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 2.33;
    letter-spacing: normal;
    text-align: center;
    color: #ffffff;
}

.footer-description a {
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 2.33;
    letter-spacing: normal;
    text-align: center;
    color: #ffffff;
    text-decoration: none;
}

/* iPhone 6,7,8 반응형 스타일 */
@media only screen
and (min-device-width: 375px)
and (max-device-width: 667px) {
    .footer-container {
        width: 100%;
        flex: 1;
    }

    .footer-content {
        width: 100%;
    }
}
