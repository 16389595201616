/* DialogTerm.css */
.dialog-term-overlay {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(0, 0, 0, 0.5);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1000;
}

.dialog-term-modal {
    background: white;
    border-radius: 8px;
    width: 90%;
    max-width: 500px;
    max-height: 90vh;
    display: flex;
    flex-direction: column;
}

.dialog-term-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 1rem;
    border-bottom: 1px solid #dee2e6;
}

.dialog-term-title {
    margin: 0;
    font-size: 1.25rem;
    font-weight: 500;
}

.dialog-term-close {
    background: transparent;
    border: none;
    font-size: 1.5rem;
    cursor: pointer;
    padding: 0.5rem;
    margin: -0.5rem -0.5rem -0.5rem auto;
}

.dialog-term-body {
    padding: 1rem;
    overflow-y: auto;
    white-space: pre-line;
}

.dialog-term-footer {
    padding: 1rem;
    border-top: 1px solid #dee2e6;
    display: flex;
    justify-content: flex-end;
}

.dialog-term-button {
    padding: 0.5rem 1rem;
    background-color: #6c757d;
    color: white;
    border: none;
    border-radius: 4px;
    cursor: pointer;
    font-size: 0.875rem;
    line-height: 1.5;
}

.dialog-term-button:hover {
    background-color: #5a6268;
}
